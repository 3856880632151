import Label from "components/Label/Label";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useUpdateBillingMutation } from "store/api/customersApi";
import { useCollege, useUser } from "store/utils/hooks";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Student } from "data/data";
import Checkbox from "shared/Checkbox/Checkbox";
import Radio from "shared/Radio/Radio";
import { RadioGroup } from "@headlessui/react";
import SelectCurso from "components/SelectCurso";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { v1 as uuid } from 'uuid'
import { Card, Divider } from "@mui/material";
import { useContainerDimensions } from "hooks/useContainerDimensions";

export interface AccountPageProps {
  className?: string;
}

export const BasicForm = ({
  register,
  errors,
  getValues,
  setValues
}: {
  register: any;
  errors: any;
  getValues:any;
  setValues:any;
}) => {
  return (
    <>
      <div>
        <Label>Nombre</Label>
        <Input
          className="mt-1.5"
          {...register("name", {
            required: "El nombre es requerido",
          })}
          error={errors.name?.message}
        />
      </div>
      {/* ---- */}
      <div>
        <Label>Apellidos</Label>
        <Input
          className="mt-1.5"
          {...register("last_name", {
            required: "Los apellidos son requeridos",
          })}
          error={errors.last_name?.message}
        />
      </div>
      {/* ---- */}
      <div>
        <Label>Email</Label>
        <div className="mt-1.5 flex">
          <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
            <i className="text-2xl las la-envelope"></i>
          </span>
          <Input
            type="email"
            className="!rounded-l-none"
            placeholder="example@email.com"
            {...register("email", {
              required: `${"El email es requerido"}`,
              pattern: {
                value:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Entre un email válido",
              },
            })}
            error={errors.email?.message}
          />
        </div>
      </div>
      {/* ---- */}
      <div>
        <Label>Teléfono</Label>
        <div className="mt-1.5 flex">
          <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
            <i className="text-2xl las la-phone-volume"></i>
          </span>
          <Input
            className="!rounded-l-none"
            {...register("phone_number", {
              required: "El teléfono es requerido",
            })}
            error={errors.phone_number?.message}
          />
        </div>
      </div>
    </>
  );
};

export const AddressForm = ({
  register,
  errors,
}: {
  register: any;
  errors: any;
}) => {
  return (
    <>
      {/* <div>
        <Label>País</Label>
        <div className="mt-1.5 flex">
          <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
            <i className="text-2xl las la-globe"></i>
          </span>
          <Input
            className="!rounded-l-none"
            {...register("country", {
              required: "El país es requerido",
            })}
            error={errors.country?.message}
          />
        </div>
      </div> */}
      {/* ---- */}
      <div>
        <Label>Dirección</Label>
        <div className="mt-1.5 flex">
          <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
            <i className="text-2xl las la-map-signs"></i>
          </span>
          <Input
            className="!rounded-l-none"
            {...register("address", {
              required: "La dirección es requerida",
            })}
            error={errors.address?.message}
          />
        </div>
      </div>
      {/* ---- */}
      <div>
        <Label>Código postal</Label>
        <div className="mt-1.5 flex">
          <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
            <i className="text-2xl las la-envelope-open"></i>
          </span>
        <Input
          className="!rounded-l-none"
          {...register("potal_code", {
            required: "El código postal es requerido",
          })}
          error={errors.potal_code?.message}
        />
        </div>
      </div>
      {/* ---- */}
      <div>
        <Label>Ciudad</Label>
        <div className="mt-1.5 flex">
          <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
            <i className="text-2xl las la-map-marker"></i>
          </span>
          <Input
            className="!rounded-l-none"
            {...register("city", {
              required: "La ciudad es requerida",
            })}
            error={errors.city?.message}
          />
        </div>
      </div>
      {/* ---- */}
      <div>
        <Label>Provincia</Label>
        <div className="mt-1.5 flex">
          <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
            <i className="text-2xl las la-map"></i>
          </span>
          <Input
            className="!rounded-l-none"
            {...register("province", {
              required: "La provincia es requerida",
            })}
            error={errors.province?.message}
          />
        </div>
      </div>
    </>
  );
};

type FormValues = {
  first_name:string
  last_name:string
  address_1:string
  city:string
  state:string
  country:string
  postcode:number
  familia:boolean
  phone:string
  email:string
}



export const StudentSection = ({item = { 
    id:"",
    name:"",
    genre:"Masculino"
},planeFormat,setArray, setItem, onDelete, className="", ...rest} :{
  planeFormat?:boolean
  item:Student
  setItem?:React.Dispatch<React.SetStateAction<Student>>
  setArray?:(callback:(prev:Student[])=>Student[])=>void
  onDelete?:(id:(number | string))=>void
  className?:string
}) => {
  const [student, setStudent] = useState<Student>({genre:'masculino',...item})
  const cardRef = useRef()
  const {width} = useContainerDimensions(cardRef)
  const college = useCollege()

  useEffect(()=>{
    if(college){
      setStudent((prev)=>({
        ...prev,
        course_id:college.courses[0]?.id,
      }))
    }
  },[college])
  useEffect(()=>{
    setItem?.(student)
    setArray?.((prev)=>prev.map((it)=>it.id===student.id?student:it))
  },[student])
  const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>setStudent((prev)=>({
    ...prev,
    [e.target.name]:e.target.value
  }))
  
  console.log({width})
  const renderDeleteBtn = () => onDelete && (<div className="w-full flex justify-around">
  <ButtonSecondary
  type="button"
  onClick={()=>onDelete?.(item.id)} className="hover:text-red-500 w-full"
  >
    <i className="text-2xl las la-trash"></i>
    <span className="text-sm">Eliminar</span>
  </ButtonSecondary>
</div>)
  return <Card ref={cardRef} className={`space-y-4 p-6 h-min ${planeFormat?'!shadow-none p-0':''} ${className}`} {...rest} >
    <div>
    <Label>Nombre del alumno</Label>
    <div className="mt-1.5">
      <Input
        name="name"
        placeholder="Nombre"
        onChange={handleChange}
        defaultValue={item.name}
      />
    </div>
  </div>
  <div>
    <Label>Curso del alumno</Label>
    <div className="mt-1.5">
    <SelectCurso defaultValue={student.course_id} onChange={(course_id)=>{
      setStudent((prev)=>({
        ...prev,
        course_id
      }))
    }} />
    </div>
  </div>
  <div className={`pb-2`}>
    <Label>Sexo del alumno</Label>
    <div className="mt-1.5 flex items-center gap-4">
      <RadioGroup 
        className={"flex gap-4"}
        onChange={(value)=>{
          setStudent((prev)=>({
            ...prev,
            genre:value,
          }))
        }}
      >
        <RadioGroup.Option value={"masculino"}>
          <Radio name={`sexo_${item.id}`} defaultChecked={student.genre === "masculino" || !student.genre}  id="masculino" label="Masculino"  />
        </RadioGroup.Option>
        <RadioGroup.Option value={"femenino"}>
          <Radio name={`sexo_${item.id}`} id="femenino" label="Femenino" defaultChecked={student.genre=== "femenino"} />
        </RadioGroup.Option>
      </RadioGroup>
      {width > 500 && renderDeleteBtn()}
    </div>
  </div>
  {width <= 500 && onDelete && <Divider/>}
  {width <= 500 && renderDeleteBtn()}
  </Card>
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const [update, res] = useUpdateBillingMutation();
  const user = useUser();
  const dispatch = useDispatch();
  const college = useCollege()
  const [students, setStudents] = useState<Student[]>([])
  useEffect(()=>{
    setStudents(user?.students || [])
  },[user])
  useEffect(() => {
    if (res.isError) {
      toast.error(res.error.data.message)
    } else if (res.isSuccess) {
      console.log({res})
      dispatch(setUser(res.data.data));
      toast.success("Usuario actualizado");
    }
  }, [res]);
  console.log({user})
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = useForm<FormValues>({
    defaultValues: useMemo(() => {
      return user;
    }, [user]),
  });
  function onSubmit(data: any) {
    delete data.schools
    update({...data,students:students.map((it)=>{
      if(typeof it.id === 'string'){
        delete it.id
      }
      return it
    })});
  }
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Detalles de la cuenta
          </h2>
          <div className="">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              {/* <div className="flex-shrink-0 flex items-start"> */}
              {/* AVATAR */}
              {/* <div className="relative rounded-full overflow-hidden flex">
                <img
                  src={avatarImgs[2]}
                  alt=""
                  className="w-32 h-32 rounded-full object-cover z-0"
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div> */}
              {/* </div> */}
              <div className=" mt-10 md:mt-0 md:pl-16 max-w-3xl max-md:space-y-4 md:grid md:grid-cols-2 gap-6">
                {/*  */}
                <div>
                  <Label>Nombre</Label>
                  <Input
                    className="mt-1.5"
                    {...register("name", {
                      required: "El nombre es requerido",
                    })}
                    error={errors.name?.message}
                  />
                </div>
                {/* ---- */}
                <div>
                  <Label>Apellidos</Label>
                  <Input
                    className="mt-1.5"
                    {...register("last_name", {
                      required: "Los apellidos son requeridos",
                    })}
                    error={errors.last_name?.message}
                  />
                </div>
                {/* ---- */}
                <div>
                  <Label>Dirección</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-map-signs"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      {...register("address", {
                        required: "La dirección es requerida",
                      })}
                      error={errors.address?.message}
                    />
                  </div>
                </div>
                {/* ---- */}
                <div>
                  <Label>Código postal</Label>
                  <div className="mt-1.5 flex">
                    <Input
                      {...register("potal_code", {
                        required: "El código postal es requerido",
                      })}
                      error={errors.potal_code?.message}
                    />
                  </div>
                </div>
                {/*  */}
                {/* ---- */}
                <div>
                  <Label>Ciudad</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-map-marker"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      {...register("city", {
                        required: "La ciudad es requerida",
                      })}
                      error={errors.city?.message}
                    />
                  </div>
                </div>
                {/*  */}
                <div>
                  <Label>Provincia</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-map"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      {...register("province", {
                        required: "La provincia es requerida",
                      })}
                      error={errors.province?.message}
                    />
                  </div>
                </div>
                {/* <div>
                  <Label>País</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-globe"></i>
                    </span>
                    <NcDropDown
                      title="Pais"
                      data={countries}
                      onClick={() => {}}
                    />
                    <Input
                      className="!rounded-l-none"
                      {...register("country", {
                        required: "El país es requerido",
                      })}
                      error={errors.country?.message}
                    />
                  </div>
                </div> */}
                {/* <div></div> */}
                {/*  */}
                <div>
                  <Label>Email</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-envelope"></i>
                    </span>
                    <Input
                      type="email"
                      className="!rounded-l-none"
                      placeholder="example@email.com"
                      {...register("email", {
                        required: `${"El email es requerido"}`,
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Entre un email válido",
                        },
                      })}
                      error={errors.email?.message}
                    />
                  </div>
                </div>
                {/* ---- */}
                <div>
                  <Label>Teléfono</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-phone-volume"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      {...register("phone_number", {
                        required: "El teléfono es requerido",
                      })}
                      error={errors.phone_number?.message}
                    />
                  </div>
                </div>
                
                {/* ---- */}
                {Boolean(college.family_discount) && <div className="mt-1.5 col-span-2">
                  <Checkbox
                    labelClassName="text-sm"
                    label="Familia numerosa"
                    name={register("big_family").name}
                    checked={user.big_family}
                    className="opacity-50 pointer-events-none"
                  />
                  {!user.big_family && <span className="mt-4 text-sm">Puedes solicitar los descuentos por familia numerosa en el <a className="text-blue-500 hover:underline fon-semibold" href="https://momoiescolar.com/familia-numerosa" target="_blank">siguiente enlace</a></span>}
                </div>}
                {/* ----- */}
                <div className="pt-2 max-sm:space-y-4 col-span-2 sm:grid-cols-2 sm:grid sm:gap-4">
                  {
                    students?.map((it)=>{
                      return <StudentSection onDelete={(removed)=>setStudents((prev)=>prev.filter(({id})=>id!==removed))} item={it} setArray={setStudents}/>
                    })
                  }
                  <div className="col-span-2">
                    <ButtonSecondary
                      disabled={res.isLoading}
                      fontSize="text-sm"
                      onClick={()=>setStudents((prev)=>[...prev,{
                        id:uuid()
                      }])}
                      type="button"
                      // className="col-span-2"
                    >
                      Agregar Estudiante
                    </ButtonSecondary>
                  </div>
                </div>
                {/* ---- */}
                <div className="pt-2">
                  <ButtonPrimary
                    loading={res.isLoading}
                    type="submit"
                    className="col-span-2"
                  >
                    Guardar
                  </ButtonPrimary>
                </div>
                
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
