import { RadioGroup } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber";
import { LineItem, Order } from "data/types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";
import Textarea from "shared/Textarea/Textarea";
import {
  useCreateRefundMutation, useGetOrderQuery, useGetOrdersQuery,
} from "store/api/ordersApi";
import { useOrders } from "store/utils/hooks";
import getEuro from "utils/getEuro";
import SelectReason from "./SelectReason";
import { Alert } from "@mui/material";
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import { useUploadImage } from "utils/uploadImage";
import { SEND_PRICE } from "constants/data";
import { _renderLoading } from "components/SectionSliderProductCard";

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const SelectSendOption = ({setSend, send, collegeLabel, addressSendLabel, addressPrice}
  :{setSend:(value:string)=>void, send:string, collegeLabel?:string, addressSendLabel?:string, addressPrice?:number}
  ) => {
    const refundOptions = [
      {
        value: "colegio",
        label: collegeLabel || "Devolución en Colegio - Gratuita",
      },
      {
        value: "direccion",
        label: addressSendLabel || "Devolución en dirección de envío – Gastos de transporte",
      },
    ];
  return (<RadioGroup onChange={setSend} value={send} className={"space-y-4 mt-4"}>
      {refundOptions.map(({ value, label }) => {
        return (
          <RadioGroup.Option
            key={label}
            value={value}
            className={({ active, checked }) =>
              `${
                active
                  ? "ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300"
                  : ""
              }
          ${checked ? "bg-sky-900/75 text-white" : "bg-white"}
            relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
            }
          >
            {({ active, checked }) => (
              <>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={`font-medium  ${
                          checked ? "text-white" : "text-gray-900"
                        }`}
                      >
                        {label}
                      </RadioGroup.Label>
                    </div>
                  </div>
                  {checked && (
                    <div className="shrink-0 text-white">
                      <CheckIcon className="h-6 w-6" />
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        );
      })}
    </RadioGroup>
  );
};

const RefundsPage = () => {
  const id = useSearchParam("order");
  const navigate = useNavigate();
  const { data, isLoading } = useGetOrderQuery(Number(id));
  const order = data as Order | undefined;
  const line_items_map: { [key: number]: LineItem } = order 
    ? order.line_items.reduce((acc: { [key: number]: LineItem }, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {})
    : {};
  const [createRefund, res] = useCreateRefundMutation();
  // const [updateOrder, res] = useUpdateOrderMutation();
  const [items, setItems] = useState<{
    [key: number]: number;
  }>({});
  const [reason, setReason] = useState<string>();
  const [reasonOption, setReasonOption] = useState<string>("Prenda equivocada");
  const [to, setTo] = useState<string>("colegio");
  const [step, setStep] = useState<number>(0);
  const {upload, isLoading:isLoadingImage} = useUploadImage()
  const refundCost = to!=='colegio'?(SEND_PRICE*-1):0;
  const refund = order
    ? order.line_items.reduce((acc, curr) => {
        if (items[curr.id]) {
          return (acc += curr.price * items[curr.id]);
        }
        return acc;
      }, 0)
    : 0;
  const [refundImage, setRefundImage] = useState<File | undefined>()
  useEffect(() => {
    if (res.isSuccess) {
      navigate("/ok-devolution");
    }
  }, [res]);
  const handleRefund = async () => {
    if (step !== 2) {
      setStep((prev) => prev + 1);
      return;
    }
    const line_items: {
      product_id: number;
      devolution: number;
    }[] = [];
    Object.keys(items).forEach((it: string) => {
      const curr = line_items_map[Number(it)];
      line_items.push({
        product_id: curr.product_id,
        devolution: items[Number(it)],
      });
    });
    const img_url = refundImage && await upload(refundImage)
    createRefund({
      order:id,
      body:{
        line_items,
        reason,
        reasonOption,
        sendPrice: refundCost,
        send_type:to==='colegio'?'school':'direction',
        refundImage:img_url,
      }
    });
  };
  const renderProductList = () => {
    const products = order?.line_items?.filter((it)=>{
      const sended = !!it.partial_send
      const max = it?.partial_send - it?.devolution - (it?.pending_devolution || 0) 
      const remained = max > 0
      return sended && remained
    })
    return (
      <ul className="space-y-4">
        {isLoading && _renderLoading()}
        {!products?.length && !isLoading && <li className="mt-6">No hay productos disponibles para devolución.</li>}
        {products?.map((it, idx) => {
          return (
            <li
              key={`refund--${it.id}`}
              className={`${items[it.id] ? "" : "grayscale"}`}
            >
              <label className="flex gap-4 items-center">
                <Checkbox
                  checked={!!items[it.id]}
                  onChange={(checked) => {
                    setItems((prev) => ({
                      ...prev,
                      [it.id]: checked ? 1 : 0,
                    }));
                  }}
                  name={it.id.toString()}
                />{" "}
                {renderProduct(it, idx)}
              </label>
            </li>
          );
        })}
      </ul>
    );
  };
  function getImageFileObject(imageFile:{dataUrl:string, file:File}) {
    const mb = (imageFile.file.size / (1024*1024))
    console.log({imageFile})
    if (mb > 10) {
      alert("Por favor, seleccione una imagen que no exceda el tamaño máximo de 10 megabytes.");
      return;
    }
    setRefundImage(imageFile.file)
  }

  function runAfterImageDelete() {
    setRefundImage()
  }
  const renderReason = () => {
    return (
      <>
      <h6 className="mb-2">Razón</h6>
      <SelectReason onChange={(value)=>{
        setReasonOption(value)
      }}/>
      <Textarea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder="Comentarios"
        className="mt-4"
      ></Textarea>
      <div className="mt-4">
        <h6 className="mb-4">Fotografía de los desperfectos (Opcional)</h6>
        <div className="gap-2 items-center flex flex-col">
        <ImageUploader
          onFileAdded={(img) => getImageFileObject(img)}
          onFileRemoved={() => runAfterImageDelete()}
        />
        <i className="text-sm">Tamaño límite de subida 10mb</i>
        </div>
      </div>
      </>
    );
  };
  const renderByCollegeOrDirection = () =>{
    return <>
      <SelectSendOption setSend={setTo} send={to}/>
      {to != "colegio" && <Alert severity="warning" className="mt-4 items-center">El coste de envío de la devolución será descontado del importe a reembolsar una vez verificado el estado de los productos.</Alert>} 
    </>
  }
  const steps = [renderProductList, renderReason, renderByCollegeOrDirection];
  const renderProduct = (item: LineItem, index: number) => {
    const { id, name, price, amount, image, attributes, partial_send, devolution, pending_devolution } = item;
    console.log({partial_send, devolution, rest:partial_send-devolution})
    return (
      <div key={id} className={`flex py-5 last:pb-0`}>
        <div className="h-16 relative overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
        </div>

        <div className="ml-4 flex w-full flex-col">
          <div>
            <div className="flex justify-between gap-8">
              <div>
                <h3 className="text-base font-medium ">{name}</h3>
              </div>
              {/* <Prices price={item.total} className="mt-0.5" /> */}
            </div>
            <div className="flex gap-4 text-black/75">
              {attributes.map((attr)=>{
                return <li>{attr.name}:{attr.terms?.[0]?.name}</li>
              })}
              </div>
          </div>
          <div className="flex w-full flex-1 items-end justify-end text-sm">
            <NcInputNumber
              min={0}
              max={partial_send - devolution - (pending_devolution || 0)}
              onChange={(value) => {
                setItems((prev) => ({ ...prev, [id]: value }));
              }}
              defaultValue={items[id] || 0}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="container py-8">
      <div className="mb-16">
        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
          {order?`Pedido #${order?.id}`:"Cargando..."}
        </h2>
        <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
          <Link to={"/#"} className="">
            Inicio
          </Link>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <Link to={"/account-my-order"} className="">
            Pedidos
          </Link>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <span className="underline">Reembolso</span>
        </div>
      </div>

      <div className="grid grid-cols-1 max-sm:m-4 sm:grid-cols-2 gap-8">
        <div className="w-full">
          <div className="w-full flex gap-2 justify-between items-center mb-4">
            <Badge
              name="Productos"
              className="cursor-pointer"
              onClick={() => {
                setStep(0);
              }}
            ></Badge>
            <div
              className={`flex-1 bg-blue-500 h-[1px] opacity-50 ${
                step === 0 ? "grayscale" : ""
              }`}
            ></div>
            <Badge
              name="Motivo"
              onClick={() => {
                if (step !== 0) {
                  setStep(1);
                }
              }}
              className={`${step === 0 ? "grayscale" : "cursor-pointer"}`}
            ></Badge>
            <div
              className={`flex-1 bg-blue-500 h-[1px] opacity-50 ${
                step !== 2 ? "grayscale" : ""
              }`}
            ></div>
            <Badge
              name="Devolución"
              className={`${step !== 2 ? "grayscale" : "cursor-pointer"}`}
            ></Badge>
          </div>
          {steps[step]()}
        </div>
        <div className="space-y-4">
          {order && (
            <div className="text-end text-lg space-y-2">
              {/* <div className="flex justify-between gap-8">
                <b>Pedido</b>
                <p>{getEuro(order.total)}</p>
              </div> */}
              <div className="flex justify-between gap-8">
                <b>Pedido</b>
                <p>{getEuro(order?.total_price)}</p>
              </div>
              <div className="flex justify-between gap-8">
                <b>Devolución</b>
                <p>{getEuro(refund)}</p>
              </div>
              <div className="flex justify-between gap-8">
                <b>Envío</b>
                <p>{getEuro(refundCost)}</p>
              </div>
              <div className="h-[1px] w-full bg-slate-900 gap-8"></div>
              <div className="flex justify-between">
                <b>Total a reembolsar</b>
                <p>{getEuro(refund + refundCost)}</p>
              </div>
            </div>
          )}
          <ButtonPrimary
            loading={res.isLoading || isLoadingImage}
            disabled={
              (step === 0 &&
                (Object.keys(items).length === 0 ||
                  Object.values(items).every((it) => it === 0)))
            }
            onClick={handleRefund}
          >
            {step === 2 ? "Crear Solicitud" : "Siguiente"}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default RefundsPage;
