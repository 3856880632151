import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
import { useCollege } from "store/utils/hooks";
import CollegeSignUp from "containers/CollegeSignUp";
import { getSessionsIds, useLoadSessions } from "utils/session";
import { _renderLoading } from "components/SectionSliderProductCard";
import RefundsPage from "containers/Refunds/RefundsPage";
import OkPage from "components/OkPage";
import KoPage from "components/KoPage";
import FaqsPage from "containers/FaqsPage";
import Terms from "containers/PoliticsPages/Terms";
import Privacy from "containers/PoliticsPages/Privacy";
import Cookies from "containers/PoliticsPages/Cookies";
import { Alert } from "@mui/material";
import PageForgotPassword from "containers/PageForgotPassword/PageForgotPassword";
import { useLocalStorage } from "react-use";
import OkDevolutionPage from "components/OkDevolutionPage";
import GroupedDetailPage from "containers/ProductDetailPage/GroupedDetailPage";
import CommonPoliticsPage from "containers/PoliticsPages/CommonPoliticsPage";
import AccountRemove from "containers/AccountPage/AccountRemove";

export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/home2", component: PageHome2 },
  { path: "/home3", component: PageHome3 },
  //
  { path: "/home-header-2", component: PageHome },
  { path: "/product-detail", component: ProductDetailPage },
  { path: "/grouped-detail", component: GroupedDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  { path: "/page-collection-2", component: PageCollection2 },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  //
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-remove", component: AccountRemove },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  //
  { path: "/refunds", component: RefundsPage },
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/forgot-pass", component: PageForgotPassword },
  { path: "/subscription", component: PageSubcription },
  //
  { path: "/ok", component: OkPage },
  { path: "/ko", component: KoPage },
  //
  { path: "/ok-devolution", component: OkDevolutionPage },
  //
  { path: "/faqs", component: FaqsPage},
  { path: '/devolutions', component: () => <CommonPoliticsPage title="Devoluciones" politic="devolution"/> },
  { path: "/terms", component: Terms},
  { path: "/privacy", component: Privacy},
  { path: "/cookies", component: Cookies}
];

const RouterProvider = () => (
  <BrowserRouter>
    <Toaster />
    <ScrollToTop />
    <SiteHeader />
    <Routes>
      {pages.map(({ component: Component, path }, index) => {
        return <Route key={index} element={<Component />} path={path} />;
      })}
      <Route element={<Page404 />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);

const CredentialsLoader = ({
  setIsPending,
}: {
  setIsPending: (isPending: boolean) => void;
}) => {
  const { isLoading } = useLoadSessions();
  useEffect(() => {
    setIsPending(isLoading);
  }, [isLoading]);
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      {_renderLoading()}
    </div>
  );
};

const MyRoutes = () => {
  const college = useCollege();
  const [isPending, setIsPending] = useState<boolean>(false);
  return !college || isPending ? (
    localStorage.getItem("accessToken") ? (
      <CredentialsLoader setIsPending={setIsPending} />
    ) : (
      <CollegeSignUp />
    )
  ) : (
    <RouterProvider />
  );
};

export default MyRoutes;
